<template>
  <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="icon_personalize"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g>
      <g>
        <image
          style="overflow: visible; opacity: 0.75"
          width="103"
          height="103"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABnCAYAAAAdQVz5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAC+RJREFUeNrsnQ1y2zgMhUlJjp20
2dP0/ifoaTaN4z9ZO50xtwjyHgDKsi2n8QxHTpMmND8+EARJMKWv19fr61X/yvdU2Z8/f55d3x8/
fgxfcK4DI1L/4V5h5TuAkYN1zgEggwduTrDyTKFkUD/r36LKGYL/NgtIecZAsvGewWJg0JO9nw2o
fGMoDIgu3vcsszaQEvneu991bUh5BlD0+wa8b8j3LfUgAEfw/ki+z1R1NUj5xlAyAaFLJl8zQLpx
JYgj+fpIgA23gpSvBIZBQQ3fOk8EicFhEHrn6YH6/29cElC+klosKLLxZenAe/3z8nchs1YaFkE4
nJ76fQ9+3lPTRSDlK4PRCkEwfj8Xp6csGphWUPl7g4Ij1SJBHFTZq+8hWEdDSZMDylcwY9p0WTAW
pCBQrYKTgYeGwGggqFiwkMm7iJnLFwKD1CKhdADGg3jKgiC1CpAFpwdwJIidKnvxlLAOBqR3KpoK
UL4gGG3CJBQEYnkq+r0FCJk2ZtIsMNtT0e81MA2JmbpJAOULg5Hma6GALEVZqefSAIRMm/zbA1GO
hqPBlLJRTw2r/P8eQJoUUJ4YTGkoaXYYlNWpPIL3ElQNHMusWXAkkLfTU7+3IJXyYSJ7DqA8MRit
GAlGA5HlSYB5BAp6UE7DWIdADvY7oJg38VyfnrJIUDs1JjEzNxpQvhCYhRhbSgOXRn8iRcJaEbNm
eWuWK43Uo83aRoFYk1J+TirpIFQ0GaA84RiDzJhUS4HwTRUNaKXglN8lHYqGjDeJxNNKo8kBvfR8
CWcDwLyqIiEhFfVq8joaUL4wGGm2Cozv6vkNwEHmzPLSWITA8tqQedNwCpBf6vmqzN5FAHVnOhPI
VdZgJJTn01OWAk6qRrrYTDG1gU9LQUvx9MxppEOg+N7lxhwyzsiJZfkwKwWmQHkWcJ6BclbKQ1uI
wd9SjLfYZimoV96bNG9aOS/i+SK+flUOw06MQ0hBYfV0EzgA0itbCjgSzD/q+V3A0eYMgUGBznPW
c/rT7z+cnnsnZCSnBp5q2HrQsfzb7/aMAOoqVWaNNdIBKKaqAPlHgCnlmzJnCAxzmT0wFqDh9DuP
p2cPYnddYLJrjW0D8NgiG1Dq4JAos57LyHHmCZgzCUirRk82rdhZQ5a3UxBQ6cGNgFRMTwv+dksW
+JKxohpaB4qopxuhmobAWQHVWCZNzmkejLhZRC2WWcvg69JY8rP0IEjLVl0HY6LrLdzlqHq6M1XT
EXMmvbFn4KU9KTAPyiMba8YsYFmYl0GZm0yWwpkZk2OXV5B5C6mnO1M1D8Skadf5WYF5MsC0Z6jF
M20ZwBrEZ+uTv8vHitsdSOS6J+OPqZ5mxIpmq9ZjpIeGlPNdDf7IAbBWONn2qNopA/s9DVlz0k4O
mrd9dzxPbao/1N/actyNVE1LKo8iAd9AxXWUuXVMyZQrtrLXZtBZvXDQYKwR7UDUuvxMKyLXIfXU
mrXW8NIeVfzsCUBZgZm3HnjTiPFlKkhZeHNWOMgLou7I0kIrnII8yqwZewEaEENbkWgzm2A+kPnD
NcEgSFmph0VAkKXQn30FLASLolPT1gUrbcXQtE1mEWYrTnUrMMyTa4SCWuWlSScABU7lutBSKKgT
TkIjHASqoKbCEUDR56VaINNrMkgxXbI3Bd5qc72loEZ1SmvxcAWcgoUXaUDq6QJjjeXJIG9m5ShG
r8XMAQxTkOw8w6n+R+AMbVUbvJHIdqsmvHXKAY1ljTdLsCeArf23jlc2l4NcORhLXBAVsSX2RXC5
w5/nkIp1wFOzds0snDX/uYGxADXOwuKStIe3JpRD3lrF/jO2xQktVHkbAOd6LtUKXem5XqQd3DUp
Pe40IyrVAVk/ALV0YKEsnzHLvyUkZkXYLiO0Y7Wr7aRNoOdIrwVtpX0ApSOe2b2oxuqoyIPrSDug
hTvr6ErYIciGnKObzts7V02kPdje7wVRTbg9GicAx5alUWU6RzHNnamGqadxFGS1i7trSHJogr3E
OikgK+Rt+kt3qBoLEmqTRfKXu5uIepozotJt4I/fuzmr6biN0y5tqts5VDXPsY4ItpWVyHesmkin
ZW1SBahx4ks52YehmqBi0idQTWSCivYgWPshzPBVM8LGeiV/YjCREE+0jVwr0lRKmp2KjmbWyJ8E
itcuVvuE26IJViAHK5M+sVoiKkrBzhpKstSc2Xs+mzIupaxRbTMGjpWzbPjL4QzBdhoNh+Uiq8nE
9DeBYunCYCIJ0lawvZoRvYFlYbIq81mUNQTbxWqfcFs0FRU5BouZaekTqoWdAfLayIXUjJAoyrzU
O5A+EyB27gfl2dEb2quGAk85HpDo5u2zB8cZmTOrbbw2ibRNeMzxKmHlhDk6NvdeVRNRDGqXKjCW
t2adpWS76vfJTt5z704BG4NRjgN24qCv6bj/wyHnRBAglhJLV0ZX4F4hISi6XQ7BdoEJJORLcmgq
5KvNmZWrDPWWezZvVntE87f1te0RcaXlkTlUkR0oloLuST2DM/7qfAYodxtST/kdVa40q9CRqEZW
hCXrOdy5eqyzOag9tgYgmJcgFCEQ9s5LrmDlK9Npr/raSs1YNdq817RDdQK9mnkOOjCkE8jpiqFc
MPcwQR0CU4l9wqnBdHvsgCd71jzHyh3DKrVJHzMpRZL1zA3QEHACWEK9TcIZDmUbIG8NfvYuoJxj
4oeGZMVK/pfH9OfgEDr+UU4tl86Bjl3MKcKMEhrtCRSZA4eZtd5zp6lylN3zeg7K8Mey+u2IB3e0
4ks3BHMknhlSi/7cEpCb5ovNMz3lZKAeWUmdp+z30Yd1wscf9FbUlP6ss8uUJ7dQkAWmdxQj87Np
5WxVp0RRk7oxR3ltyRl3ZAosneFvTWSO3MpbKcgD0xPFvKnPqVNNbpzxJiUnxVcXrHwp7LDqW+L5
oKOJ5IqCUL7OfAUwzDuV4+uGgHkF6tmS8SY8z+sqKl8aTvv42/TxKMgi+ae5dBIGuflOvi4ByZv5
98RCSCg6xSSzFJGpRN2SgeEYaBuMZP5LlVL5N1L5SNQ2nRldQL/rGJhoa2/01fiMa+AMyPGm6nKK
rlL6Uj3FDrNTB9HE3LJ06X2qLWsz3jDB+DI4g7/ueBrIC1EOGl+rVOPC+U1VnBdB9rgRgKyCzuew
daMCdSCmEHly2TFdEVe5d5wdqZZ/BRitnI3hpVVd6dKN6HFaPez0Aev9A+mx/Wm8Oqb6TIVD0KSl
ZC8i7oGjsxaqeRFgyvNFgdkY8bQqs+zCAepJ4gOxi++sDH+sx5Zi5fg8pvE5PhNYKOsNMBsFRsKR
YH4Rk7Y/RzU1ymErgiUTxT7xTH9etEGvIj6kP1lrp86OyzrFnjgA0py9EOUgcxaJJaZJ4Aj16DS8
+dSQKfkn2PRcia0iXiuvtDXzR57ZCygyr7QO19Bl6Whe6XPS5aNDvIv0/mKJW2VkrwGzU57ZRoBZ
A89MlrUyZ9p9/rCR8Frp8mXIpVfqsSLcViK5x9Nz6rsMmGJr7jL4lfy7DKwYWvXrb7kFhC2zS5d5
q6LLr2rMkV+zyfTtbgExAN3L/TnygqMdiJmhK1rWBAoaY84eZ86GEwQ0x5untGq2BI684EjfPrVV
48u8bp5yAM31zja2c2hLVjT1nW1sdVNHnG9/Z1tgDJrbbYfetq4NALUFSrGupJzPbYdBQHO7J1R6
aXqvmb4v1Nt/djEwky5izfyGXWuP9z59vKx1l/g2Y5YCf5437BJAKc3zbmp9JeUh2XdTs0vEB7S8
Psu7qQEgS0W3vNXduhhC3/jeE6WEd9DMCk4AkFZT68DSsbWWBEJZBGNQY0MPlOSd0POODU4O5mJw
HDPHIKGER+ipFYPSMbIFNa0k9Kw6hHwJKFeBQ1QUUROD0BhrRd5VlIMDq+par0uDuQqcICQ0kWWL
dlPcdmi9H24N5epwApCYomoS/rCFQWsXj3eF5NWh3AwOgWSBSsnOSlWzTM0a30x3cm0oN4dTCcr7
txQAlJKfc+YdhFtBmRUcAxKqYw58liEACv7crYHMFk4QVjpDOWnOMO4KzghY7mvOML5eX6+v1xSv
/wQYAPUupjyInf7OAAAAAElFTkSuQmCC"
          transform="matrix(1 0 0 1 1.5 1.5)"
        ></image>
        <g>
          <circle class="st0" cx="50" cy="50" r="35" />
          <circle class="st1" cx="50" cy="50" r="35" />
        </g>
      </g>
      <g>
        <g>
          <path
            class="st2"
            d="M55.73,37.92H50.4c-0.77,0-1.44,0.55-1.58,1.3l-3.64,18.67c-0.16,0.8,0.45,1.54,1.27,1.54h5.33
				c0.77,0,1.44-0.55,1.58-1.3l3.64-18.67C57.15,38.66,56.54,37.92,55.73,37.92z"
          />
          <circle class="st2" cx="65.29" cy="59.72" r="3.49" />
          <path
            class="st2"
            d="M73,50.39l-5.21-6.43c-0.31-0.38-0.78-0.61-1.27-0.61h-7.79c-0.39,0-0.72,0.28-0.79,0.67l-2.2,13.72
				c-0.14,0.89,0.54,1.7,1.45,1.7h2.85c0.15-2.76,2.44-4.96,5.25-4.96s5.09,2.19,5.25,4.96h1.4c1,0,1.82-0.81,1.82-1.82v-5.08
				C73.76,51.75,73.49,50.99,73,50.39z M68.1,50.32h-7.44c-0.39,0-0.71-0.32-0.71-0.71v-3.61c0-0.39,0.32-0.71,0.71-0.71h4.53
				c0.21,0,0.42,0.1,0.55,0.26l2.91,3.61C69.02,49.64,68.69,50.32,68.1,50.32z"
          />
          <g>
            <path
              class="st3"
              d="M34.25,42.32h12.22c0.74,0,1.34-0.6,1.34-1.34c0-0.74-0.6-1.34-1.34-1.34H34.25c-0.74,0-1.34,0.6-1.34,1.34
					C32.91,41.72,33.52,42.32,34.25,42.32z"
            />
            <path
              class="st3"
              d="M44.18,46.18H31.96c-0.74,0-1.34,0.6-1.34,1.34c0,0.74,0.6,1.34,1.34,1.34h12.22c0.74,0,1.34-0.6,1.34-1.34
					C45.52,46.79,44.92,46.18,44.18,46.18z"
            />
            <path
              class="st3"
              d="M42.17,52.73H29.95c-0.74,0-1.34,0.6-1.34,1.34c0,0.74,0.6,1.34,1.34,1.34h12.22c0.74,0,1.34-0.6,1.34-1.34
					C43.51,53.33,42.91,52.73,42.17,52.73z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Envio",
};
</script>
<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: none;
  stroke: #5c48f7;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #5c48f7;
  stroke: #5c48f7;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ff5717;
}
#icon_personalize{
  width: 80px !important;
  height: auto !important;
}
</style>
