<template>
  <v-slide-y-transition>
    <router-view />
  </v-slide-y-transition>
</template>
<script>
export default {
  name: "App"
};
</script>
