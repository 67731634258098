import * as types from '../mutation-types'

export const state = {
  text: '',
  timeout: '',
  name: '',
  title: '',
  y: '',
  x: ''
}

export const mutations = {
  [types.SHOW_MESSAGE] (state, payload) {
    state.text = payload.text
    state.timeout = payload.timeout
    state.title = payload.title
    state.name = payload.name
    state.y = payload.y
    state.x = payload.x
  }
}

export const actions = {
  showSnack ({ commit }, payload) {
    commit(types.SHOW_MESSAGE, payload)
  }
}
