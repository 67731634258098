import Vue from 'vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/components'
import VueMeta from 'vue-meta'
import moment from '@/plugins/moment';
import formatMoney from '@/plugins/currency';
import i18n from '@/plugins/i18n'
import axios from '@/plugins/axios'
import service from '@/services'
import NumericDirective from '@/plugins/directives/numeric.directive'
import vuescroll from 'vue-scroll'

import "@fontsource/krub";
import "@/assets/styles/main.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

import vuetify from './plugins/vuetify'

Vue.use(vuescroll)
Vue.use(VueMeta)
Vue.prototype.service = service
Vue.prototype.axios = axios
Vue.prototype.moment = moment
Vue.prototype.formatMoney = formatMoney


Vue.config.productionTip = false;
Vue.use(NumericDirective)

new Vue({
  axios,
  moment,
  service,
  formatMoney,
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
