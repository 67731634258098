<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 50"
    style="enable-background: new 0 0 60 50"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M39.31,19.08L39.31,19.08l0-13.42c0-2.41-1.95-4.39-4.34-4.39H9.16c-2.39,0-4.34,1.97-4.34,4.39v13.41l0,0.01
		c-1.44,0.11-2.57,1.32-2.57,2.8v9.7c0,1.55,1.25,2.81,2.78,2.81h1.92l0,0c-0.37,0.34-0.61,0.83-0.61,1.37v0.84
		c0,1.01,0.81,1.84,1.82,1.84h3.58c1,0,1.82-0.82,1.82-1.84v-0.84c0-0.54-0.24-1.03-0.61-1.37c0,0,18.31,0,18.34,0
		c-0.37,0.34-0.61,0.83-0.61,1.37v0.84c0,1.01,0.81,1.84,1.82,1.84h3.58c1,0,1.82-0.82,1.82-1.84v-0.84c0-0.54-0.24-1.03-0.61-1.37
		l0,0h1.8c1.54,0,2.78-1.26,2.78-2.81v-9.7C41.88,20.4,40.75,19.19,39.31,19.08z M10.81,30.13c-1.72,0-3.12-1.41-3.12-3.15
		s1.4-3.15,3.12-3.15c1.72,0,3.12,1.41,3.12,3.15S12.53,30.13,10.81,30.13z M33.38,30.13c-1.72,0-3.12-1.41-3.12-3.15
		s1.4-3.15,3.12-3.15c1.72,0,3.12,1.41,3.12,3.15S35.1,30.13,33.38,30.13z M37.66,19.07l-1.91,0c0.05-0.21,0.08-0.42,0.08-0.64
		c0-2.2-2.73-3.97-6.1-3.97s-6.1,1.78-6.1,3.97c0,0.22,0.03,0.43,0.08,0.64l-17.23,0V6.01c0-1.73,1.4-3.14,3.11-3.14h24.98
		c1.71,0,3.11,1.41,3.11,3.14V19.07z"
      />
      <ellipse class="st0" cx="29.59" cy="9.37" rx="4.28" ry="4.33" />
      <path
        class="st0"
        d="M46.74,42.2h-3.76c-1.24,0-2.25-1.02-2.25-2.27v0c0-1.25,1.01-2.27,2.25-2.27h3.76v-3.8
		c0-1.25,1.01-2.27,2.25-2.27h0c1.24,0,2.25,1.02,2.25,2.27v3.8H55c1.24,0,2.25,1.02,2.25,2.27v0c0,1.25-1.01,2.27-2.25,2.27h-3.76
		V46c0,1.25-1.01,2.27-2.25,2.27h0c-1.24,0-2.25-1.02-2.25-2.27V42.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AsignarConductor",
};
</script>
<style type="scss" scoped>
.st0 {
  fill: #0b0045;
}
</style>
