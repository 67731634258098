<template>
  <div>
    <Loading
      :active.sync="show"
      loader="dots"
      :opacity="0.5"
      :height="100"
      :width="100"
      lock-scroll
      color="#ff9800"
    />
    <div v-if="show" id="center">{{$t('components.app.loader.processing')}}</div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Loader",
  components: {
    Loading,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "loader/SHOW_LOADER") {
        this.show = state.loader.show;
      }
    });
  },
};
</script>

<style scoped>
#center {
  z-index: 10000;
  color: #000;
  display: flex;
  top: 0;
  right: 0;
  bottom: -71px;
  font-size: 1em;
  color: #0B0045;
  left: 0;
  position: fixed;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
</style>
