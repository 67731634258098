<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M37.45,1.29H12.68c-4.91,0-8.92,4.01-8.92,8.92v21.68c0,4.91,4.01,8.92,8.92,8.92h0.95c0,0,0,3.44,0,4.81
		c0,1.37,0.95,3.09,2.67,3.09c1.03,0,1.72-1.03,2.41-1.72c0.69-0.69,6.01-6.19,6.01-6.19h12.74c4.91,0,8.92-4.01,8.92-8.92V10.21
		C46.37,5.3,42.36,1.29,37.45,1.29z M43.01,31.25c0,3.7-3.03,6.73-6.73,6.73H23.95c0,0-5.06,5.07-6.01,6.02
		c-0.95,0.95-1.41,0.95-1.41,0.32c0-0.63,0-3.8,0-4.75c0-1.9-1.73-1.58-1.73-1.58h-0.94c-3.7,0-6.73-3.03-6.73-6.73V11.42
		c0-3.7,3.03-6.73,6.73-6.73h22.43c3.7,0,6.73,3.03,6.73,6.73V31.25z"
      />
      <g>
        <g>
          <image
            style="overflow: visible; opacity: 0.75"
            width="46"
            height="57"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA5CAYAAABJahkUAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA7lJREFUeNrsmutu2zAMhUVZbdr3
f9gt8UXbAGs4Yw4pynGb/YgBwlnbOR+ZI16kpPS6XlfokrMeVGt1nyUi9b8B78F+pRNyEvDIc+oZ
DsgDwEKeIQHgeoYD8gC0qNdiPBNBrdfDDsggNIPNHXAE9WwIXgahGXA2HGDQm7rj67/Rj8DLAWgE
nfY7GsJraMvuot+DLwegM0Bra79r/w+hV7g3Qwe3f9++igdfDkA3yAL3YsA3cIRddsv7PQF0GL4E
14EQ6LfdCtybAyjBDYBncG6B5y9WqgxHPBDtBvoO9gY2qYhvAF32e1bOVfX30ot6CURaR7tBX+B+
AQeKAsdo337bNZB9mFMhcIy6Fe0/sB+7IXyBiDaZNOhJRVtnHJrbWdRLoDoybSP4JzjQos7Ar0RG
VpoUyErhrCJGsfHAPyHyTecNboFoi1qwmGWm/d9ZwVO59LJKJlLx4BFcYGFautdmVV874oZMInJB
rX8Y4FlFelY2Kf2LlovWeQm0A+JEHdPixYj4RCL9vsuHFa5Q1Eunf/G0rh1ARxA8wyKcVeFiVVcI
Qx1Jh+L0Kaz8F1I9sZQvBLioaIsllUdKfnKcsZxrb8ycQ+ipI5M7B/LBaUkCkhKni5wINBtMxEt3
h+blzjzpfTqT8dqL9kPg1ZghzTFMZSUJAGfjE71L2TkIXDsTTfV6DXgv5oQYCzOdJRU2O1ZnFLNq
ggedItC9rMKiF5khK+k1LL16C77bi0QWn6XpyAC8OfI5vJOVD0JvB6w6k30yHDsklZEor8pQtzhz
rmra7zlyWOM9Xa/EFjJ6sRZ2VY4MwZeB3K0/7pX01bNqYbG1vSmbyaegHTD3Fgv+EBJ8NeSyGkMA
9tXYnyQFft2NOdCcqAT+oXTIIj2rvlrAwUzAf4JdAXwelUwEnOl5NqDT/nsc1VYV8R8EfiGyqaPb
E9XY+9uUTG7Q5eEQ3HpuMcARnul9i2w/l4ESryWie24cjCcCjhtCGHGUykrahn7E1QLVW2I4+Or+
AofgAgszKXndAP5mSMWrtN2IVwK/wgaldm6F0WxSFVl/UrOSiJbJFtkrL+woj0Rdw7Of640fcWQ2
GwVpi5b+SOXEfWu9JYxgE9kbTGRhryPVc2h/HKJeiSSqAa4nml5K7fYr3onE0TMgtk1hDbmRbvK8
M6CObFhFZaOX1+t83albYE9RArNiTc845wwc0qaBk+Xk9SGnnyw70Q8Pt+lZZ/kBB4Y3k77t2xMd
B+Jv+qzvqxxx4uxvCL2u1xW8fgkwAKliBq39NlACAAAAAElFTkSuQmCC"
            transform="matrix(1 0 0 1 3.0632 -8)"
          ></image>
          <g>
            <path
              class="st0"
              d="M18.08,16.31c0,4.79,4.38,5.11,5.87,5.43c1.49,0.32,5.81,0.41,5.81,2.89s-3.06,2.89-4.46,2.89
					c-1.41,0-3.33-0.46-3.33-0.46s0-1.51,0-2.22c0,0-2.35,0-3.19,0c0,0,0,2.87,0,4.33c1.57,1.43,4.76,1.54,4.76,1.54s0,2.35,0,3.25
					c0,0,2.33,0,3.35,0c0,0,0-1.84,0-3.22c5.38,0,6.79-4.68,6.79-6.34c0-1.66-0.59-3.15-2.54-4.34s-4.98-1.6-6.03-1.73
					c-1.05-0.14-3.43-0.76-3.43-2.33s1.87-2.46,3.89-2.46s3.11,0.59,3.11,0.59s0,1.22,0,2.17c0,0,2.76,0,3.38,0c0,0,0-3.01,0-4.41
					c0,0-2.7-1.41-5.03-1.41V7.03h-3.33v3.46C23.71,10.49,18.08,10.96,18.08,16.31z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OfertarViaje",
};
</script>
<style type="scss" scoped>
.st0 {
  fill: #0b0045;
}
</style>
