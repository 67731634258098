import * as types from '../mutation-types'
import Cookies from 'js-cookie'

// state
export const state = {
  user: localStorage.getItem('user'),
  token: Cookies.get('token'),
}

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, { token}) {
    state.token = token
    Cookies.set('token', token, { sameSite: 'none', expires: null, secure: true })
  },

  [types.SAVE_USER_SUCCESS](state, { user }) {
    state.user = JSON.stringify(user)
    localStorage.setItem('user', JSON.stringify(user));
  },

  [types.SAVE_USER_FAILURE](state) {
    state.user = null
    Cookies.remove('token')
  },

  [types.USER_LOGOUT](state) {
    state.user = null
    state.token = null
    localStorage.removeItem('user')
    Cookies.remove('token')
  },

}

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, {
      token: payload.token
    })
  },

  saveUser({ commit }, payload) {
    commit(types.SAVE_USER_SUCCESS, {
       user: payload
    })
  },

  logout ({ commit }) {
    commit(types.USER_LOGOUT)
  },

}
