import store from '@/store'
import Cookies from 'js-cookie'

export default async (to, from, next) => {
  if (store.getters['auth/token']) {
    next()
  } else {
    Cookies.remove('token')
    next()
  }
}
