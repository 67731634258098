import * as types from '../mutation-types'

export const state = {
  viewOffers: localStorage.getItem('viewOffers'),
  viewAssign: localStorage.getItem('viewAssign'),
  viewConfirmed: localStorage.getItem('viewConfirmed')
}

export const mutations = {

  [types.VIEW_OFFERS](state, { viewOffers }) {
    state.viewOffers = viewOffers
    localStorage.setItem('viewOffers', viewOffers);
  },

  [types.VIEW_ASSIGN](state, { viewAssign }) {
    state.viewAssign = viewAssign
    localStorage.setItem('viewAssign', viewAssign);
  },

  [types.VIEW_CONFIRMED](state, { viewConfirmed }) {
    state.viewConfirmed = viewConfirmed
    localStorage.setItem('viewConfirmed', viewConfirmed);
  },
}

export const getters = {
  viewOffers: state => state.viewOffers,
  viewAssign: state => state.viewAssign,
  viewConfirmed: state => state.viewConfirmed,
}

export const actions = {
  saveViewOffers({ commit }, payload) {
    commit(types.VIEW_OFFERS, payload)
  },
  saveViewAssign({ commit }, payload) {
    commit(types.VIEW_ASSIGN, payload)
  },
  saveViewConfirmed({ commit }, payload) {
    commit(types.VIEW_CONFIRMED, payload)
  },
}
