import * as types from '../mutation-types'

// state
export const state = {
  reloadTrips: false,
}

// mutations
export const mutations = {
  [types.SHOW_RELOADTRIPS](state, { reloadTrips }) {
    state.reloadTrips = reloadTrips
  },
}

// getters
export const getters = {
  reloadTrips: state => state.reloadTrips,
}

// actions
export const actions = {
  saveReloadTrips ({ commit }, payload) {
    commit(types.SHOW_RELOADTRIPS, payload)
  }
}
