<template>
  <div id="snackbar">
    <v-snackbar
      v-model="show"
      :color="defineColor"
      :timeout="timeout"
      multi-line
      rounded="0"
      :top="y === 'top'"
      :bottom="y === 'bottom'"
      :right="x === 'right'"
      :left="x === 'left'"
      elevation="0"
    >
      <v-layout align-center pr-4>
        <v-icon v-if="name === 'error'" class="mr-3" large>
          fa-solid fa-frown
        </v-icon>
        <v-icon v-if="name === 'success'" class="mr-3" large>
          fa-solid fa-smile
        </v-icon>
        <v-icon v-if="name === 'warning' || name === 'validate'" class="mr-3" large>
          fa-solid fa-exclamation-triangle
        </v-icon>
        <v-icon v-if="name === 'info'" class="mr-3" large>
          fa-solid fa-circle-exclamation
        </v-icon>
        <v-layout column>
          <div class="bt-1">
            <strong>{{ title }}</strong>
          </div>
          <div v-if="name === 'validate'">
            <div v-for="(v, k) in text" :key="k">
              <p v-for="error in v" :key="error" class="mt-1 mb-0">
                - {{ error }}
              </p>
            </div>
          </div>
          <div v-else>
            {{ text }}
          </div>
        </v-layout>
      </v-layout>
      <template #action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="show = false">
          <v-icon>
            fa-solid fa-times
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  data () {
    return {
      show: false,
      y: '',
      x: '',
      name: '',
      title: '',
      text: '',
      timeout: -1
    }
  },
  computed: {
    closeWindow () {
      return 'Cerrar'
    },
    defineColor () {
      let color = ''
      if (this.name === 'error') {
        color = 'red'
      } else if (this.name === 'warning' || this.name === 'validate') {
        color = 'warning'
      } else if (this.name === 'success') {
        color = 'success darken-2'
      } else if (this.name === 'info') {
        color = 'info'
      } else {
        color = 'red'
      }
      return color
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/SHOW_MESSAGE') {
        this.text = state.snackbar.text
        this.timeout = state.snackbar.timeout
        this.title = state.snackbar.title
        this.name = state.snackbar.name
        this.y = state.snackbar.y ? state.snackbar.y : 'top'
        this.x = state.snackbar.x ? state.snackbar.x : 'right'
        this.show = (!!state.snackbar.text)
      }
    })
  }
}
</script>
<style scoped>
#snackbar ::v-deep .v-snack .layout{
  font-family: Krub !important;
}
</style>
