import { render, staticRenderFns } from "./Envio.vue?vue&type=template&id=06fa5aee&scoped=true&"
import script from "./Envio.vue?vue&type=script&lang=js&"
export * from "./Envio.vue?vue&type=script&lang=js&"
import style0 from "./Envio.vue?vue&type=style&index=0&id=06fa5aee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fa5aee",
  null
  
)

export default component.exports