<template>
  <v-select
    v-model="localSelected"
    offset-y
    :items="newDatos"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :loading="loading"
    :no-data-text="$t('components.app.select.no_results_not_found')"
    outlined
    persistent-hint
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>
<script>
import validate from "@/plugins/validate";

export default {
  name: "Select",
  props: {
    init: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
    selected: {
      type: [Number, String],
      default: 0,
    },
    items: {
      type: Array,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ...validate,
      loading: false,
      givens: []
    };
  },
  computed: {
    newDatos() {
      return this.url !== "" ? this.givens : this.items;
    },
    localSelected: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    if (this.init && this.url !== "") {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.axios
        .get(this.url)
        .then((response) => {
          this.givens = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.v-select-list :deep(.v-list-item__title) {
  font-size: 1em !important;
  color: #666;
}
</style>
