<template>
  <v-avatar :size="size" :style="{ 'background-color': backgroundColor }">
    <strong
      :class="'initials'"
      :style="{ color: fontColor, 'font-size': fontSize }"
    >
      {{ avatar }}
    </strong>
  </v-avatar>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
      required: true
    },
    lastName: {
      type: String,
      default: null,
      required: false
    },
    size: {
      type: Number,
      default: 45,
      required: false
    },
    color: {
      type: String,
      default: null,
      required: false
    },
    initialsOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    fontSize () {
      return (this.size / 38.0) * 25 + 'px'
    },
    fontColor () {
      if (this.initialsOnly) {
        return 'transparent'
      }
      return 'white'
    },
    backgroundColor () {
      if (this.initialsOnly) {
        return 'transparent'
      }
      return '#F05A24'
    },
    avatar () {
      if (this.lastName !== null && this.lastName !== null) {
        return (this.name[0]).toUpperCase() + (this.lastName[0]).toUpperCase() || 'E'
      } else {
        return (this.name[0]).toUpperCase() || 'E'
      }
    }
  }
}
</script>
