import * as types from '../mutation-types'

export const state = {
  search: '',
}

export const mutations = {
  [types.SHOW_SEARCH] (state, payload) {
    state.search = payload.search
  }
}

export const getters = {
  search: state => state.search,
}

export const actions = {
  showSearch ({ commit }, payload) {
    commit(types.SHOW_SEARCH, payload)
  }
}
