<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M47.57,20.07l-5.67-5.04c-0.49-0.44-0.77-1.06-0.77-1.71V8.89c0-2.49-2.02-4.5-4.5-4.5h-1.61
		c-1.28,0-2.31,1.04-2.31,2.31v0.08c0,0.03-0.04,0.05-0.06,0.03l-5.7-5.07c-0.89-0.79-2.23-0.79-3.12,0L2.87,20.35
		c-2.29,2.04-0.85,5.82,2.21,5.82h0.86c0.46,0,0.83,0.37,0.83,0.83l0.05,18.86c0,1.46,1.18,2.65,2.65,2.65h15.96h15.96
		c1.46,0,2.65-1.18,2.65-2.65l-0.05-19.69h1.27C48.46,26.17,49.97,22.21,47.57,20.07z M44.25,23.51h-1.67
		c-0.77,0-1.39,0.62-1.39,1.39v18.98c0,0.7-0.57,1.27-1.27,1.27H25.38H10.84c-0.7,0-1.27-0.57-1.27-1.27v-19.2
		c0-0.64-0.52-1.16-1.16-1.16H6.22c-0.81,0-1.2-1-0.6-1.55L24.68,4.62c0.4-0.36,1.01-0.37,1.42-0.01l6.7,5.91
		c0.53,0.47,1.36,0.08,1.34-0.62l-0.02-1.16c0.08-0.64,0.63-1.13,1.28-1.13h0.84c0.29,0,0.57,0.08,0.83,0.22l0.14,0.08
		c0.54,0.31,0.87,0.89,0.87,1.51v4.64c0,0.66,0.29,1.29,0.78,1.73l6.24,5.47C45.98,22.04,45.43,23.51,44.25,23.51z"
      />
      <path
        class="st1"
        d="M21.5,36.12l-7.18-7.18c-0.33-0.33-0.33-0.87,0-1.2l0.51-0.51c0.33-0.33,0.87-0.33,1.2,0l5.47,5.47
		c0.33,0.33,0.87,0.33,1.2,0L34.59,20.8c0.33-0.33,0.87-0.33,1.2,0l0.51,0.51c0.33,0.33,0.33,0.87,0,1.2L22.7,36.12
		C22.37,36.45,21.83,36.45,21.5,36.12z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AsignarEmpresa",
};
</script>
<style type="scss" scoped>
.st0 {
  fill: #0B0045;
}
.st1 {
  fill: #0B0045;
  stroke: #0B0045;
  stroke-miterlimit: 10;
}
</style>
