<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 35 35"
    style="enable-background: new 0 0 35 35"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M26.38,20.91c0,0-0.71,0.66-1.85,0.42c0.04,0.16,0.08,0.32,0.12,0.48c0,0-3.89-1.24-6.96-1.24
			s-6.96,1.24-6.96,1.24c0.03-0.16,0.07-0.32,0.12-0.48c-1.13,0.23-1.85-0.42-1.85-0.42c-0.2,0.74-0.31,1.53-0.31,2.33
			c0,4.97,4.03,9,9,9s9-4.03,9-9C26.68,22.44,26.58,21.65,26.38,20.91z M12.55,28.16c-1.21-1.26-1.95-2.96-1.97-4.84
			c0,0,2.36,0.14,2.86,1.53C13.99,26.41,12.55,28.16,12.55,28.16z M17.68,30.35c-1.46,0-2.81-0.44-3.94-1.19
			c0,0,0.51-1.61,3.94-1.61s3.94,1.61,3.94,1.61C20.49,29.91,19.14,30.35,17.68,30.35z M22.82,28.16c0,0-1.45-1.74-0.88-3.31
			c0.5-1.39,2.86-1.53,2.86-1.53C24.77,25.19,24.02,26.9,22.82,28.16z"
        />
        <path
          class="st0"
          d="M12.54,18.34c1.29-1.36,3.12-2.2,5.14-2.2s3.85,0.85,5.14,2.2c0.14-1.16,1.14-1.54,1.14-1.54
			c-1.62-1.58-3.84-2.55-6.28-2.55s-4.66,0.97-6.28,2.55C11.4,16.8,12.4,17.18,12.54,18.34z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M10.72,16.78c0.54-0.01,1.61,0.64,1.61,1.57s-0.18,2.05-1.61,2.7c-0.89,0.42-1.93-0.45-1.96-1.06
			C8.72,19.38,9.43,16.81,10.72,16.78z"
        />
        <path
          class="st0"
          d="M24.65,16.78c-0.54-0.01-1.61,0.64-1.61,1.57s0.18,2.05,1.61,2.7c0.89,0.42,1.93-0.45,1.96-1.06
			C26.65,19.38,25.93,16.81,24.65,16.78z"
        />
        <circle class="st0" cx="17.81" cy="8.44" r="5.2" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Asignar",
};
</script>
<style type="scss" scoped>
.st0 {
  fill: #0b0045;
}
</style>
