import Vue from 'vue'
import DataTable from '@/components/app/DataTable'
import Logo from '@/components/assets/Logo'
import Avatar from '@/components/app/Avatar'
import Snackbar from '@/components/app/Snackbar'
import IconAlert from '@/components/assets/IconAlert'
import DatePicker from '@/components/app/DatePicker'
import DateTimePicker from '@/components/app/DateTimePicker'
import TimePicker from '@/components/app/TimePicker'
import LogoModule from '@/components/assets/LogoModule'
import SelectAutocomplete from '@/components/app/SelectAutocomplete'
import SelectAutocompleteFilter from '@/components/app/SelectAutocompleteFilter'
import Select from '@/components/app/Select'
import Loader from '@/components/app/Loader'
import Dialog from '@/components/app/Dialog'
import DataTableOffers from '@/components/app/dataTableOffers/DataTableOffers'

// All Globally Registered Components of folder (Assets and App)
const components = {
  DataTable,
  Logo,
  Avatar,
  DateTimePicker,
  Snackbar,
  IconAlert,
  DatePicker,
  TimePicker,
  LogoModule,
  SelectAutocomplete,
  SelectAutocompleteFilter,
  Select,
  Loader,
  Dialog,
  DataTableOffers
}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))
