<template>
  <v-dialog v-model="datetimeDialog" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="datetimeFormatted"
        readonly
        outlined
        hide-details
        append-icon="fa-regular fa-calendar-days"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-text-field>
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar height="36" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#date">
            <v-icon size="small">fa-solid fa-calendar-days</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon size="small">fa-solid fa-clock</v-icon>
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            :locale="lenguageDateTime"
            scrollable
            :min="min"
            :max="max"
            class="rounded-0"
            @input="tab = 'time'"
            full-width
          />
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            format="24hr"
            v-model="time"
            :min="minTimeNew"
            :max="maxTime"
            scrollable
            class="rounded-0"
            ampm-in-title
            full-width
            @click:minute="closeDialog"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "DateTimePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    default: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: null,
    time: null,
    minTime: "",
    locale: Cookies.get("locale"),
    tab: false,
    datetimeDialog: false,
  }),
  computed: {
    datetimeFormatted() {
      let val = this.default
        ? this.moment(new Date().toISOString()).format("DD-MM-YYYY HH:mm")
        : "";
      return this.value ? this.moment(this.value).format("DD-MM-YYYY HH:mm") : val;
    },
    lenguageDateTime() {
      return this.locale === "es" || this.locale == undefined ? "ES-CO" : "";
    },
    maxTime() {
      return this.max === null || this.max === "" ? "" : this.moment(this.max).format("H:mm");
    },
    minTimeNew() {
      if (this.minTime === "") {
        if (
          this.moment(this.date).format("DD-MM-YYYY") === this.moment(this.min).format("DD-MM-YYYY")
        ) {
          return this.moment(this.min).format("H:mm");
        } else if (
          this.moment(this.date).format("DD-MM-YYYY") ===
          this.moment(new Date().toISOString()).format("DD-MM-YYYY")
        ) {
          return this.moment(new Date().toISOString()).format("H:mm");
        }
        return "";
      }
    },
  },
  methods: {
    closeDialog() {
      this.$nextTick(() => {
        this.datetimeDialog = false;

        this.date =
          this.date == null || this.date.includes("NaN")
            ? this.moment().format("DD-MM-YYYY")
            : this.date;

        this.time =
          this.time == null || this.time.includes("NaN") ? this.moment().format("h:mm") : this.time;

        this.$emit("input", `${this.date} ${this.time}`);
        setTimeout(() => (this.tab = "date"), 300);
      });
    },
  },
};
</script>
