<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 35 35"
    style="enable-background: new 0 0 35 35"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M18.09,29.76H6.47c-1,0-1.82-0.82-1.82-1.82V15c0-1,0.82-1.82,1.82-1.82h20.47c1,0,1.82,0.82,1.82,1.82v4.01
		c0,0-0.02,1.29,1.02,1.29c1.04,0,1.02-1.3,1.02-1.3V7.8c0-1.95-1.59-3.54-3.54-3.54H25.1c-0.44,0-0.81,0.36-0.81,0.81v2.54
		c0,1.06-0.87,1.92-1.93,1.9c-1.04-0.02-1.86-0.92-1.86-1.96V5.06c0-0.44-0.36-0.81-0.81-0.81h-5.89c-0.44,0-0.81,0.36-0.81,0.81
		v2.48c0,1.04-0.82,1.94-1.86,1.96c-1.06,0.02-1.93-0.84-1.93-1.9V5.06c0-0.44-0.36-0.81-0.81-0.81H6.15
		c-1.95,0-3.54,1.59-3.54,3.54v20.53c0,1.95,1.59,3.54,3.54,3.54h11.91c0,0,0.94-0.11,0.94-1.03C19,29.91,18.09,29.76,18.09,29.76z"
      />
      <path
        class="st0"
        d="M11.11,8.95c0.78,0,1.41-0.64,1.41-1.41V3.4c0-0.78-0.64-1.41-1.41-1.41S9.69,2.62,9.69,3.4v4.14
		C9.69,8.31,10.33,8.95,11.11,8.95z"
      />
      <path
        class="st0"
        d="M22.41,8.95c0.78,0,1.41-0.64,1.41-1.41V3.4c0-0.78-0.64-1.41-1.41-1.41S21,2.62,21,3.4v4.14
		C21,8.31,21.64,8.95,22.41,8.95z"
      />
      <path
        class="st0"
        d="M25.67,19.94c-3.84,0-6.95,3.11-6.95,6.95c0,3.84,3.11,6.95,6.95,6.95c3.84,0,6.95-3.11,6.95-6.95
		C32.62,23.05,29.5,19.94,25.67,19.94z M29.84,27.57c0,0.45-0.37,0.82-0.82,0.82h-6.71c-0.45,0-0.82-0.37-0.82-0.82v-1.35
		c0-0.45,0.37-0.82,0.82-0.82h6.71c0.45,0,0.82,0.37,0.82,0.82V27.57z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Asignar",
};
</script>
