import i18n from '@/plugins/i18n'

const required = (propertyType, isSelect = 0) => {
  const texto = isSelect ? i18n.t('plugins.validate.select') : i18n.t('plugins.validate.enter')
  return v => !!v || `${i18n.t('plugins.validate.must')} ${texto} ${propertyType}`
}

const maxLength = (propertyType, maxLength) => {
  return v => (v <= maxLength) || `${i18n.t('plugins.validate.enter')} ${propertyType} ${i18n.t('plugins.validate.must_be_less_than')} ${maxLength}`
}

function formatBytes(a, b = 2) {
  if (a === 0) {
    return '0 Bytes'
  }
  const c = b < 0 ? 0 : b
  const d = Math.floor(Math.log(a) / Math.log(1024))
  return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
}

const fileSize = (size) => {
  if (size) {
    return v => (v.size <= size) || i18n.t('plugins.validate.limit') + ' (' + formatBytes(size) + ')'
  }
}

export default {
  required,
  maxLength,
  fileSize
}
