<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        hide-details
        append-icon="fa-regular fa-clock"
        readonly
        outlined
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      ampm-in-title
      v-model="time"
      full-width
      @click:minute="$refs.menu.save(time)"
    >
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      time: '',
      menu2: false,
    };
  },
  watch:{
    time(newVal){
      this.$emit("update", newVal);
    }
  }
};
</script>
