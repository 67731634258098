import * as types from '../mutation-types'

export const state = {
  show: ''
}

export const mutations = {
  [types.SHOW_LOADER] (state, payload) {
    state.show = payload.show
  }
}

export const actions = {
  showLoader ({ commit }, payload) {
    commit(types.SHOW_LOADER, payload)
  }
}
