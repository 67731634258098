//auth.js
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const USER_LOGOUT = 'LOGOUT'
export const SAVE_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const SAVE_USER_FAILURE = 'FETCH_USER_FAILURE'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// loader.js
export const SHOW_LOADER = 'SHOW_LOADER'

// snackbar.js
export const SHOW_MESSAGE = 'SHOW_MESSAGE'

//search.js
export const SHOW_SEARCH = 'SHOW_SEARCH'

//changeView.js

export const VIEW_OFFERS = 'VIEW_OFFERS'
export const VIEW_ASSIGN = 'VIEW_ASSIGN'
export const VIEW_CONFIRMED = 'VIEW_CONFIRMED'

//reloadTable.js
export const SHOW_RELOADTRIPS = 'SHOW_RELOADTRIPS'
