<template>
  <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="icon_personalize"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g>
      <g>
        <image
          style="overflow: visible; opacity: 0.75"
          width="104"
          height="104"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABoCAYAAAAdHLWhAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACuRJREFUeNrsnQly2zgQRQGSkrxm
LpP7nyCniXdLIqY8JVY67d8LwJ0jVqEouZwYxMNvNNFAI4Trdb2u1/Xa7BXXUMlfv36NUs+fP3+m
K6BxgJTWO60NWFw4lOiobyyAkqzfWQqsuDAw6LP1sxIFJefPZgcVF6KWCO6ez9ZzSCA8n78paw5Y
cUFgckqOmpIAyFtmBRVnNGUITmXcLVgaHFRa4y4pbDJIcWYwFYPjKRymF1DLwHgKBzk5qDgRHEkt
vNTkXrPvCFQlmDtumloBzJncz+y7Buyv/39MSHFC1fCej2B0pRHuFBZXoKQgbroohJNwPwvQkPkb
VU1xQtUgtVAAtOzYvQGqqsDYhOAkoJozAfJVjuxOCwUnKWo0NcUR4UjmjIKhILqyZ993DJ4ESTJx
CM6JwaHlk33vfkcC9U1NQ0KKI8Oh5qxmaqFAaDmQzxQYh4RUJJm3lpk1CueT3L/KB/n8yYCdgBmE
Xt9QkOLIcCoDzMEoe6AoZOo8gGijcsV0YLSigWrHghQngKOBuVHKgfxu9++QmfOOQdy8dQ3+QQC9
K8UCNQqkOBIcpBoO5haUG3KnSkKA+po4CuiDAHojd14QKKSmwSDFkeF0qkFg7ki5JfdboCI6DtU9
FXRm4w9XTwfjldxfyXcOiqppcEhxZDh7ZsoolHt2p7Bu2Di0A4BK3GwO6MjGn3cA5YXdu0JN3+dY
kOLIcG6IajoADxco9+wzV1CJeqIyB+dVEVXQy6U8s89UUR2oUSA1PUyj5BBwOFQxD6Q8kp9x9dyQ
sYd6b43zJdV6WT0Bb+7D8CAbh+eI5gFbIfwxvIKYevjL506Ac8+gdHcEiI89O8UxKJkslRyGIxiL
OpV06nm6lGdyfyamjyvpKLzUZqmo6WnaIvDWDgDOIyk/CKgHYt4QnJ3htcXgiwclw6s7gtmLnTDV
pKlHiiXR309fbemF1PQ0bXzcObAx54GB+cEA3TP1SMqphRns3HhQABOnDZhuQi/FtcOl9wb9hlUQ
mJ2Wxh1JPV9Q/iFwHoFpo3ZfcggQHA8g2og1gVRfINXCpKzHpU9COEMLEAavippC04YAHZhT4FVP
Z9r2oOdK4010jqVJ6OkVabCKgaqcs+YpMwCYiLlzm7qmwLxp6qGmrSs/mHIemVttwdHC2971c4l8
52NDRUBZUdyouO+tEPxTg329FGTEdhpl7EHOwSN77+kLp9RbjQROYoO4Z7GK9W6FZrwpqL/+vqWi
pkA91C4j9XQAHpm39gCUc3DACRkmzbqSAUwDkwMHgapLVNRk9D4qeT7P5pktuFPgeF5AhwiN8Mbm
/2dl/D1pdvwoFDTb3Y1/Z2Zy4VVlrivgrvVeMG/3QDWSQ2CFD2IYZ+2EtvQLTfhasyP3goXYW+9R
2lr0xvkQvPLItb5lkO5Y0eBYq3TGvCJTUyQ93RMA1IJ+n8IcHRrX/ApS1GMBulPAHIBDUM0MJwh/
swLPvHPM0N8JCjLn8iQVNQUTolo4wYrrIIdgbjiSd1cxJaVL3VsQOr+9KOaOzIjfXD6j2fgzGYtU
FTUZtporyIqQSkE35K3NDUeCRDtPDSAdyEz47QVI9/xvpH2oufPOhmMTl2HekIKkdQXeYNsS9ivF
HhbEev5sM+c1cVbElCrpAKKha4EjOQ6BmKSh2oC3b8pxsz2To3xd20EIU2sD5BLheJWE2sFaNtYI
5l1sgypTQciL24MKWSGDoV9Ax4akvQvytthnRmTVsahSxh9tDJJiKJJyPIs8wsJBWeMRWiW7FwJ/
oqPAGVSFXpzUe3LBxJXACQ5zpwX+rAWXMdfExeBbAM93INCKNMKLaFiZetCYJK0555sBvIv+8+fi
HJ4c2j4ibRGpRp5Xm3qmgW/PRJsDGqNdXBalclQmKCrSihUJXSMoBElrD+++pjiEgioDlLZVMa4Y
iOV6S2N0HfI2ncUSQJqCvCHirSjHahfUcT3h8yIFaQ2KYEVFtlsCEpQGljY5ezsudLWrHpLOTTqx
djMXM2B5cjy4rqpnZWOfP75hZQ3WaasevShm9rb/K7Re7VFd23DZVy4g7/ridG3av9ohTa0gK0nR
/xVGbgYts72qniAGrcxK1CBB4d+lzFlZ7VAVmDK0YFzLYZM2rBSpPVrlZyYouhS4yqxUbhovVJG0
QVgISE7GrNTHxKWg51uz0nhtaWzSTD7a3eDd6SB23MrpeVhZo7QKST0lbQCS1R4nJ6RBFCSt6Kcr
+6XKtB45r8hdRjnotF0OUsc1O22V4TJqO6SlHGt8wFyzehAclHeB7nZAkFpgXXq9B2nZOngyCCkt
SpbdXbB7LY05Wv45Tz6f5BqDwE6v3MqgZHgeUGtyra20Mp5ccyhjI2RQZY4/PLcA33bBK6b1nDWo
SHKUNCvCkwLyzjqIk8ArhuBoCfEkJfXaULsAl9pqB21/kNQO2W62x4M7CZV6F0B5TF1aEZwh2qBM
QcwGWj3HylS4RkglcLzPr45BaLd346wwgoTyrHWFrvCXdtTx3Wx8X86S3ne0LY/vAWdoRNkZVQcB
XU3mG7PVg7o8axIgtICe7gmdE5KWrZ7vqLOyNJZaEN8YlGHmeCK8V1BQZVFvapWppjnhtAYc9Mxv
CiC3efOYuCS4mBIgaW+MJ41ytyeUKmsKJSXDKeJjrgTmJeC8pgiQOzYkenGKis5gHKIKoqkjacI7
KemdmkJyxJdaLdB2dsLhz/kiKEh9F+ybCiaRcaK7n8Kf1ZMfQd52oW3941etdBy6RTAOpBjJQqAx
B5lxTy5TnhUYTR4XOwnoobrKR/IA0op+bwrlrnF24c8e0BTk3XjoobzpyHJcaa6cN6CaLj3mE1AQ
99602ZQyQF/SuyxD5dmhqIq6HlZnwkGThRoklBEkZ7ooDQjniZVnwbzR95+i01KaAtPQEgWdAl7V
zxeQ5yTD6/IQSDsCUqbJ85g0Dc67AOf3pVA1IfWYE6S9ADEV0fciBKkkGR4KnWspMYc4ZFALtvGX
UJr9l8OhCqKO0IdnDnKQlJgMEgIkbZPkDap5S7xYSWVjISAJjpSe+RWo5zcDxFMzW+oJXji5Jo6b
i5Y0kCcZXjDgdKZFS8tcZ0KS4Jydc4vcKXhiynlipg2p59wnDra2xOa5eQaslTeLT2xeenYDMmt8
W/7QRwN4zJ020WtlSkRzihzQs/MF/BS+r8n4r0x1dgMydeeCGXEU9OtSe6HDNaxE55LX6YmEDn24
xiAh/rUcT7Mn/6e2KVfrFNyVPoFo8PqPpymANPQBT03od4adpp5tHPCUCWmII9K8aWa8gI5KuHob
R6Q5IA1xyKCWNaoGbjcHpC0wPAombjuHDGZA6ntMp3WWqme2QjpDddvHdDog9TnoFqU38yYmsk4h
RkvHtnnQrQFpiKOirTO9vQrSIG37qGgBUgj5h63vgpzqzMp943WzkbmTNgNs57B1AElSUwzfz+qR
0pzlZI0KysSsBAnd0V4nyZxlT4DODsihJikxUy3ElqQcdNEJSDpk8BzwmT852xdHATMJIEVNIegp
zkoyauVMlubutRV3Co4JZxJATlBSAiLp7smqZe0n9dzTXGAmBwQgSaavJCmRBUgClZPnIUwNZ3JA
TlCawkLwn2enrUUIhkJmBzMrIMP0adCCoRorPJIMhX2DOweURQFSVGUpJLfuyaGwsBQwiwPkgDVU
ncWGXwqUxQPKBFZ8LRHIKgH1gbcGCNfrel2vsa5/BRgAdF1LqiYBDwsAAAAASUVORK5CYII="
          transform="matrix(1 0 0 1 1 1)"
        ></image>
        <g>
          <circle class="st0" cx="50" cy="50" r="35" />
          <circle class="st1" cx="50" cy="50" r="35" />
        </g>
      </g>
      <g>
        <path
          class="st2"
          d="M50,29.13c-11.52,0-20.87,9.34-20.87,20.87c0,11.52,9.34,20.87,20.87,20.87c11.52,0,20.87-9.34,20.87-20.87
			C70.87,38.48,61.52,29.13,50,29.13z M50,69.11c-10.56,0-19.11-8.56-19.11-19.11c0-10.56,8.56-19.11,19.11-19.11
			c10.56,0,19.11,8.56,19.11,19.11C69.11,60.56,60.56,69.11,50,69.11z"
        />
        <path
          class="st3"
          d="M55.42,57.92H44.58c-1.38,0-2.5-1.12-2.5-2.5V44.58c0-1.38,1.12-2.5,2.5-2.5h10.85c1.38,0,2.5,1.12,2.5,2.5
			v10.85C57.92,56.8,56.8,57.92,55.42,57.92z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Paradas",
};
</script>
<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: none;
  stroke: #5c48f7;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #5c48f7;
  stroke: #5c48f7;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ff5717;
}
#icon_personalize{
  width: 80px !important;
  height: auto !important;
}
</style>
