import Currency from "currency.js/dist/currency.min.js";


/**
* Formato a Montos con Divisa
* @param  {String} amount
* @param  {String} badge
* @param  {String} symbol
* @return {String}}
*/

//formatMoney(100000, COP, true)

export default function (amount, badge, symbol) {

  if (badge === 'COP' || badge === 'USD') {
    if (!symbol) {
      return `${Currency(amount, {
        symbol: '',
        separator: ".",
        precision: 0
      }).format()} ${badge}`
    } else {
      return Currency(amount, {
        symbol: '$',
        separator: ",",
      }).format()
    }
  }

  if (badge === 'EUR') {
    if (!symbol) {
      return `${Currency(amount, {
        symbol: '',
        separator: ".",
        precision: 0
      }).format()} ${badge}`
    } else {
      return Currency(amount, {
        symbol: '',
        precision: 0,
        separator: "."
      }).format()
    }
  }

  if (badge == null) {
    if (amount > 0) {
    return `${Currency(amount, {
      symbol: '',
      precision: 0,
      separator: "."
    }).format()} COP`
  } else {
    return ''
  }
}
}

