import axios from "axios";
import store from "@/store";
import Cookies from "js-cookie";

const route = window.location.host;

//Local se accede a la URL Local
let baseURL = route.includes("localhost")
  ? process.env.VUE_APP_URL_API_OFFERS_DV
  : route.includes("qa")
  ? process.env.VUE_APP_URL_API_OFFERS_QA
  : process.env.VUE_APP_URL_API_OFFERS;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use((request) => {
  const token = Cookies.get("token");

  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  } else {
    window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
  }

  const locale = store.getters["lang/locale"];
  if (locale) {
    request.headers.common["Accept-Language"] = locale;
  }
  return request;
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    let name, title, text;

    if (error.response) {
      const { status, data } = error.response;

      // Respuesta al no guardar la información
      if (status === 500) {
        text = data;
        title = "No se logró realizar la operación";
        name = "error";
      }

      // Respuesta no detectada del servidor
      else if (status > 500) {
        text = data;
        title = "No se logró realizar la operación";
        name = "error";
      }

      //No tienes permiso para recibir ese contenido
      else if (status == 401) {
        text = data;
        title = "No se logró realizar la operación";
        name = "error";
      } else if (status == 400) {
        text = data;
        title = "No se logró realizar la operación";
        name = "error";
      } else {
        text = data;
        title = "No se logró realizar la operación";
        name = "error";
      }

      // Ejecuta el Snackbar
      store.commit("snackbar/SHOW_MESSAGE", {
        text,
        title,
        name,
        timeout: 2500,
      });

      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
