import axios from "@/plugins/axios";

export default {

  /**
   * Cargar Información Metodo Get
   * @param  {String} url
   * @param  {String} search
   * @return {Array}}
   */

  async getData(url, search = '') {
    let data
    const searchVal = search == "" ? "" : `?search=${search}`;
    await axios.get(`${url}${searchVal}`).then((response) => {
      data = response.data
    }).catch((err) => {
      console.log(err);
    });
    return data
  },

  /**
   * Cargar Número de Registros totales
   * @param  {String} url
   * @param  {String} search
   * @return {Number}}
   */
  async getDataCount(url, search = '', resultForPage) {
    let data
    const searchVal = search == "" ? "" : `?search=${search}`;
    await axios.get(`${url}${searchVal}`).then((response) => {
      data = Math.trunc(response.data / resultForPage);
        data = response.data
    }).catch((err) => {
      console.log(err);
    });
    return data
  }
}



