<template>
  <v-autocomplete
    v-model="localSelected"
    offset-y
    :items="dataP"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :loading="loading"
    clearable
    :no-data-text="$t('components.app.selectAutocomplete.no_results_not_found')"
    outlined
    persistent-hint
    v-bind="$attrs"
    v-on="$listeners"
    dense
  >
    <!-- Cambio -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template slot="no-data"><slot name="no-data" /></template>
    <template slot="prepend-item"><slot name="prepend-item" /></template>
  </v-autocomplete>
</template>
<script>
import validate from "@/plugins/validate";

export default {
  name: "SelectAutocompleteFilter",
  props: {
    init: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    selected: {
      type: [Number, String, Array],
    },
    data: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ...validate,
      loading: false,
      givens: [],
    };
  },
  computed: {
    localSelected: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    dataP() {
      return !this.init ? this.data : this.givens;
    },
  },
  created() {
    /*
    if (this.init) {
      this.loadData();
    }*/
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.axios
        .get(this.url)
        .then((response) => {

          if (this.url === 'Offers/GetPublicationDates') {
            this.givens = typeof response.data === "string" ? [] : this.formatDate(response.data);
          } else {
            this.givens = typeof response.data === "string" ? [] :  response.data;
          }

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },

    formatDate(values) {
      const formattedPublicationDates = values.map(value => {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milisegundos = date.getMilliseconds();
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${String(milisegundos).padStart(3, "0")}`;
        return formattedDate;

      });
      return formattedPublicationDates;
    },
  },
};
</script>
<style scoped>
.v-select-list :deep(.v-list-item__title) {
  font-size: 1em !important;
  color: #666;
}
</style>
<style scoped>
.v-text-field.v-text-field--enclosed {
  padding-top: 4px !important;
}

.my-autocomplete .v-input__slot {
  font-size: 12px;
}

</style>
