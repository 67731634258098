<template>
  <svg
    id="logo"
    version="1.1"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 710 200"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            :fill="colorDefault"
            d="M133.2,77.1c0-12,2.4-20.1,7.2-24.2c4.8-4.1,13.7-6.2,26.3-6.2h1c12.6,0,21.3,2.1,26.2,6.2
				c4.8,4.1,7.3,12.2,7.3,24.2V98c0,12-2.5,20.1-7.3,24.2c-4.8,4.1-13.5,6.2-26.2,6.2h-1c-12.6,0-21.5-2.1-26.3-6.2
				c-4.8-4.1-7.2-12.2-7.2-24.2V77.1z M168.1,106.3c7,0,8.2-1.2,8.2-8.2V77c0-7.1-1.2-8.2-8.2-8.2h-1.9c-7,0-8.2,1.2-8.2,8.2v21.2
				c0,7,1.2,8.2,8.2,8.2H168.1z"
          />
          <path
            :fill="colorDefault"
            d="M258.5,79.6c0-6.6-1-10-3.1-10c-5.6,0-14.5,1.8-14.5,2.5v51.1c0,2.4-3.2,4.1-6.3,4.1
				c-12.5,0-18.7-1.3-18.7-4.1V61.5c0-3.7,4.3-7.1,12.9-10.1c8.7-3.1,18.8-4.7,30.4-4.7c13.8,0.6,19.8,4.4,22.3,12.5
				c1.8,5.7,1.9,9.4,1.9,16.9v47.2c0,2.4-3.5,4.1-6.6,4.1c-12.2,0-18.4-1.3-18.4-4.1V79.6z"
          />
          <path
            :fill="colorDefault"
            d="M328.9,70.3V100c0,4.6,0.4,4.6,3.4,3.5c1.9-0.7,5.3-2.2,7.6-2.9c1.3-0.4,3.1,1.5,4.7,5.1
				c1.6,3.5,2.1,5.4,3.1,8.4c1,3.4,0.4,5.7-1.8,7.1c-3.7,2.2-7.2,3.8-10.4,4.7c-3.2,1-7.5,1.5-12.8,1.5c-12.5,0-18.8-7.5-18.8-22.3
				V70.3h-6.3c-2.2,0-4-3.1-4-6c0-11.6,1.3-17.3,4-17.3h6.3V21.7c0-2.5,6.2-3.8,18.7-3.8c3.1,0,6.3,1.6,6.3,3.8V47H343
				c2.2,0,4,2.9,4,5.9c0,11.6-1.3,17.5-4,17.5H328.9z"
          />
          <path
            :fill="colorDefault"
            d="M394,68.9c-2.2,0-4.6,0.4-7.2,1.2c-2.6,0.7-4,1.5-4,2.2v51c0,2.4-3.2,4.1-6.3,4.1
				c-12.2,0-18.4-1.3-18.4-4.1V61.5c0-3.1,4-6.3,11.8-9.7c7.8-3.4,16-5.1,24.4-5.1c2.4,0,5,0.1,7.9,0.4c3.4,0.3,4.7,2.1,4,5.3
				c-0.7,3.5-1.3,5.6-2.6,10.1c-1.2,4.3-3.1,6.9-5,6.8C396.6,69,395.1,68.9,394,68.9z"
          />
          <path
            :fill="colorDefault"
            d="M412.1,77.1c0-12,2.4-20.1,7.2-24.2c4.8-4.1,13.7-6.2,26.3-6.2h1c12.6,0,21.3,2.1,26.2,6.2
				c4.9,4.1,7.3,12.2,7.3,24.2V98c0,12-2.5,20.1-7.3,24.2c-4.8,4.1-13.5,6.2-26.2,6.2h-1c-12.6,0-21.5-2.1-26.3-6.2
				c-4.8-4.1-7.2-12.2-7.2-24.2V77.1z M447,106.3c7,0,8.2-1.2,8.2-8.2V77c0-7.1-1.2-8.2-8.2-8.2h-1.9c-7.1,0-8.2,1.2-8.2,8.2v21.2
				c0,7,1.2,8.2,8.2,8.2H447z"
          />
          <path
            :fill="colorDefault"
            d="M548.9,108.1h-25.5c-0.7,0-0.4-0.4-0.4-0.9V22c0-2.8-7-4.1-20.4-4.1c-3.1,0-6.8,1.8-6.8,4.1v85.2
				c0,0-2.6,22.7,19,21.9c0,0,34.5,0,34.5,0c2.6,0,3.8-4.6,3.8-16.3C553.2,109.9,551.2,108.1,548.9,108.1z"
          />
          <path
            :fill="colorDefault"
            d="M124.3,112.8c0,11.8-1.1,16.3-3.8,16.3c0,0-34.5,0-34.5,0c-21.6,0.7-19-22-19-22V39.8
				c0,0-2.6-22.7,19-21.9c0,0,34.5,0,34.5,0c2.6,0,3.8,8,3.8,19.8c0,2.9-1.9,4.7-4.3,4.7H94.6c-0.8,0-0.4,0.4-0.4,0.9v63.9
				c0,0.5-0.4,0.9,0.4,0.9H120C122.4,108.1,124.3,109.9,124.3,112.8z"
          />
        </g>
      </g>
      <g>
        <path
          :fill="colorDefault"
          d="M585.9,111.4c0-2.2,12.3-3.3,22-3.3c2.4,0,5,1.4,5,3.3v14.6c0,1.9-2.6,3.3-5,3.3c-9.6,0-22-1.1-22-3.3
			V111.4z"
        />
      </g>
      <g>
        <path
          fill="#EB8D26"
          d="M585.7,62.7v16.8c7-9,16.7-15.4,27.4-19.8V42.4h28.2c2.3,0,3.7-4.5,4-13.4 C622.9,35.9,600.4,44.9,585.7,62.7z"
        />
        <path
          :fill="colorDefault"
          d="M609.5,17.9h-52c-2.7,0-4.1,6.1-4.1,18.3c0,3,1.8,6.2,4.3,6.2h28v1.3C591.1,33,599.2,22.8,609.5,17.9z"
        />
        <path
          fill="#E35A28"
          d="M641.2,17.9h-31.7c-10.3,4.9-18.3,15.2-23.8,25.9v18.9c14.7-17.8,37.2-26.8,59.6-33.6
			c0.1-1.5,0.1-3.1,0.1-4.9C645.4,21.1,643.6,17.9,641.2,17.9z"
        />
        <path
          fill="#F4BB2B"
          d="M585.7,79.5v15.1c5.4-7.4,13-13.1,21.2-17.6c2.1-0.8,4.1-1.8,6.2-2.7V59.7
			C602.4,64.1,592.7,70.4,585.7,79.5z"
        />
        <path
          fill="#ECE646"
          d="M585.7,94.5v1.8c0,2.9,6.9,4.3,20.7,4.3c3.2,0,6.7-1.8,6.7-4.3V74.2c-2.1,1-4.1,1.9-6.2,2.7
			C598.7,81.5,591.1,87.1,585.7,94.5z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: "200px",
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  computed:{
    colorDefault(){
       return this.dark ? "#0B0045" : "#FFFFFF"
    }
  }

};
</script>
