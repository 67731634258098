<template>
  <svg
    version="1.1"
    id="icon_personalize"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g>
      <g>
        <image
          style="overflow: visible; opacity: 0.75"
          width="104"
          height="104"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABoCAYAAAAdHLWhAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACuRJREFUeNrsnQly2zgQRQGSkrxm
LpP7nyCniXdLIqY8JVY67d8LwJ0jVqEouZwYxMNvNNFAI4Trdb2u1/Xa7BXXUMlfv36NUs+fP3+m
K6BxgJTWO60NWFw4lOiobyyAkqzfWQqsuDAw6LP1sxIFJefPZgcVF6KWCO6ez9ZzSCA8n78paw5Y
cUFgckqOmpIAyFtmBRVnNGUITmXcLVgaHFRa4y4pbDJIcWYwFYPjKRymF1DLwHgKBzk5qDgRHEkt
vNTkXrPvCFQlmDtumloBzJncz+y7Buyv/39MSHFC1fCej2B0pRHuFBZXoKQgbroohJNwPwvQkPkb
VU1xQtUgtVAAtOzYvQGqqsDYhOAkoJozAfJVjuxOCwUnKWo0NcUR4UjmjIKhILqyZ993DJ4ESTJx
CM6JwaHlk33vfkcC9U1NQ0KKI8Oh5qxmaqFAaDmQzxQYh4RUJJm3lpk1CueT3L/KB/n8yYCdgBmE
Xt9QkOLIcCoDzMEoe6AoZOo8gGijcsV0YLSigWrHghQngKOBuVHKgfxu9++QmfOOQdy8dQ3+QQC9
K8UCNQqkOBIcpBoO5haUG3KnSkKA+po4CuiDAHojd14QKKSmwSDFkeF0qkFg7ki5JfdboCI6DtU9
FXRm4w9XTwfjldxfyXcOiqppcEhxZDh7ZsoolHt2p7Bu2Di0A4BK3GwO6MjGn3cA5YXdu0JN3+dY
kOLIcG6IajoADxco9+wzV1CJeqIyB+dVEVXQy6U8s89UUR2oUSA1PUyj5BBwOFQxD6Q8kp9x9dyQ
sYd6b43zJdV6WT0Bb+7D8CAbh+eI5gFbIfwxvIKYevjL506Ac8+gdHcEiI89O8UxKJkslRyGIxiL
OpV06nm6lGdyfyamjyvpKLzUZqmo6WnaIvDWDgDOIyk/CKgHYt4QnJ3htcXgiwclw6s7gtmLnTDV
pKlHiiXR309fbemF1PQ0bXzcObAx54GB+cEA3TP1SMqphRns3HhQABOnDZhuQi/FtcOl9wb9hlUQ
mJ2Wxh1JPV9Q/iFwHoFpo3ZfcggQHA8g2og1gVRfINXCpKzHpU9COEMLEAavippC04YAHZhT4FVP
Z9r2oOdK4010jqVJ6OkVabCKgaqcs+YpMwCYiLlzm7qmwLxp6qGmrSs/mHIemVttwdHC2971c4l8
52NDRUBZUdyouO+tEPxTg329FGTEdhpl7EHOwSN77+kLp9RbjQROYoO4Z7GK9W6FZrwpqL/+vqWi
pkA91C4j9XQAHpm39gCUc3DACRkmzbqSAUwDkwMHgapLVNRk9D4qeT7P5pktuFPgeF5AhwiN8Mbm
/2dl/D1pdvwoFDTb3Y1/Z2Zy4VVlrivgrvVeMG/3QDWSQ2CFD2IYZ+2EtvQLTfhasyP3goXYW+9R
2lr0xvkQvPLItb5lkO5Y0eBYq3TGvCJTUyQ93RMA1IJ+n8IcHRrX/ApS1GMBulPAHIBDUM0MJwh/
swLPvHPM0N8JCjLn8iQVNQUTolo4wYrrIIdgbjiSd1cxJaVL3VsQOr+9KOaOzIjfXD6j2fgzGYtU
FTUZtporyIqQSkE35K3NDUeCRDtPDSAdyEz47QVI9/xvpH2oufPOhmMTl2HekIKkdQXeYNsS9ivF
HhbEev5sM+c1cVbElCrpAKKha4EjOQ6BmKSh2oC3b8pxsz2To3xd20EIU2sD5BLheJWE2sFaNtYI
5l1sgypTQciL24MKWSGDoV9Ax4akvQvytthnRmTVsahSxh9tDJJiKJJyPIs8wsJBWeMRWiW7FwJ/
oqPAGVSFXpzUe3LBxJXACQ5zpwX+rAWXMdfExeBbAM93INCKNMKLaFiZetCYJK0555sBvIv+8+fi
HJ4c2j4ibRGpRp5Xm3qmgW/PRJsDGqNdXBalclQmKCrSihUJXSMoBElrD+++pjiEgioDlLZVMa4Y
iOV6S2N0HfI2ncUSQJqCvCHirSjHahfUcT3h8yIFaQ2KYEVFtlsCEpQGljY5ezsudLWrHpLOTTqx
djMXM2B5cjy4rqpnZWOfP75hZQ3WaasevShm9rb/K7Re7VFd23DZVy4g7/ridG3av9ohTa0gK0nR
/xVGbgYts72qniAGrcxK1CBB4d+lzFlZ7VAVmDK0YFzLYZM2rBSpPVrlZyYouhS4yqxUbhovVJG0
QVgISE7GrNTHxKWg51uz0nhtaWzSTD7a3eDd6SB23MrpeVhZo7QKST0lbQCS1R4nJ6RBFCSt6Kcr
+6XKtB45r8hdRjnotF0OUsc1O22V4TJqO6SlHGt8wFyzehAclHeB7nZAkFpgXXq9B2nZOngyCCkt
SpbdXbB7LY05Wv45Tz6f5BqDwE6v3MqgZHgeUGtyra20Mp5ccyhjI2RQZY4/PLcA33bBK6b1nDWo
SHKUNCvCkwLyzjqIk8ArhuBoCfEkJfXaULsAl9pqB21/kNQO2W62x4M7CZV6F0B5TF1aEZwh2qBM
QcwGWj3HylS4RkglcLzPr45BaLd346wwgoTyrHWFrvCXdtTx3Wx8X86S3ne0LY/vAWdoRNkZVQcB
XU3mG7PVg7o8axIgtICe7gmdE5KWrZ7vqLOyNJZaEN8YlGHmeCK8V1BQZVFvapWppjnhtAYc9Mxv
CiC3efOYuCS4mBIgaW+MJ41ytyeUKmsKJSXDKeJjrgTmJeC8pgiQOzYkenGKis5gHKIKoqkjacI7
KemdmkJyxJdaLdB2dsLhz/kiKEh9F+ybCiaRcaK7n8Kf1ZMfQd52oW3941etdBy6RTAOpBjJQqAx
B5lxTy5TnhUYTR4XOwnoobrKR/IA0op+bwrlrnF24c8e0BTk3XjoobzpyHJcaa6cN6CaLj3mE1AQ
99602ZQyQF/SuyxD5dmhqIq6HlZnwkGThRoklBEkZ7ooDQjniZVnwbzR95+i01KaAtPQEgWdAl7V
zxeQ5yTD6/IQSDsCUqbJ85g0Dc67AOf3pVA1IfWYE6S9ADEV0fciBKkkGR4KnWspMYc4ZFALtvGX
UJr9l8OhCqKO0IdnDnKQlJgMEgIkbZPkDap5S7xYSWVjISAJjpSe+RWo5zcDxFMzW+oJXji5Jo6b
i5Y0kCcZXjDgdKZFS8tcZ0KS4Jydc4vcKXhiynlipg2p59wnDra2xOa5eQaslTeLT2xeenYDMmt8
W/7QRwN4zJ020WtlSkRzihzQs/MF/BS+r8n4r0x1dgMydeeCGXEU9OtSe6HDNaxE55LX6YmEDn24
xiAh/rUcT7Mn/6e2KVfrFNyVPoFo8PqPpymANPQBT03od4adpp5tHPCUCWmII9K8aWa8gI5KuHob
R6Q5IA1xyKCWNaoGbjcHpC0wPAombjuHDGZA6ntMp3WWqme2QjpDddvHdDog9TnoFqU38yYmsk4h
RkvHtnnQrQFpiKOirTO9vQrSIG37qGgBUgj5h63vgpzqzMp943WzkbmTNgNs57B1AElSUwzfz+qR
0pzlZI0KysSsBAnd0V4nyZxlT4DODsihJikxUy3ElqQcdNEJSDpk8BzwmT852xdHATMJIEVNIegp
zkoyauVMlubutRV3Co4JZxJATlBSAiLp7smqZe0n9dzTXGAmBwQgSaavJCmRBUgClZPnIUwNZ3JA
TlCawkLwn2enrUUIhkJmBzMrIMP0adCCoRorPJIMhX2DOweURQFSVGUpJLfuyaGwsBQwiwPkgDVU
ncWGXwqUxQPKBFZ8LRHIKgH1gbcGCNfrel2vsa5/BRgAdF1LqiYBDwsAAAAASUVORK5CYII="
          transform="matrix(1 0 0 1 1 1)"
        ></image>
        <g>
          <circle class="st0" cx="50" cy="50" r="35" />
          <circle class="st1" cx="50" cy="50" r="35" />
        </g>
      </g>
      <g>
        <g>
          <path
            class="st2"
            d="M53.88,47.49l3.35-1.68c0.34-0.17,0.74,0.08,0.74,0.46v2.46c0,0.38,0.41,0.63,0.75,0.45l4.27-2.24
				c0.17-0.09,0.27-0.26,0.27-0.45v-3.47c0-0.19,0.11-0.37,0.28-0.46l2.89-1.46c0.34-0.17,0.74,0.08,0.74,0.46v15.97
				c0,0.19-0.11,0.37-0.28,0.45L54.34,64.4c-0.34,0.17-0.74-0.07-0.74-0.45v-16C53.6,47.76,53.71,47.58,53.88,47.49z"
          />
          <path
            class="st2"
            d="M49.66,34.47l2.98-1.5c0.14-0.07,0.31-0.07,0.46,0l12.4,6.23c0.37,0.19,0.38,0.72,0.01,0.91l-2.75,1.44
				c-0.14,0.08-0.32,0.08-0.46,0.01l-12.63-6.17C49.29,35.2,49.28,34.66,49.66,34.47z"
          />
          <path
            class="st2"
            d="M40.41,39.16l3.2-1.59c0.15-0.07,0.32-0.07,0.46,0l12.29,6.3c0.37,0.19,0.37,0.71,0.01,0.91l-3.13,1.65
				c-0.15,0.08-0.32,0.08-0.47,0L40.4,40.07C40.03,39.88,40.03,39.35,40.41,39.16z"
          />
          <path
            class="st2"
            d="M39.43,41.11l12.54,6.39c0.17,0.09,0.28,0.26,0.28,0.46v16c0,0.38-0.4,0.63-0.74,0.46l-12.54-6.26
				c-0.17-0.09-0.28-0.26-0.28-0.46V41.57C38.69,41.19,39.09,40.94,39.43,41.11z"
          />
        </g>
        <g>
          <path
            class="st3"
            d="M38.67,67.13c1.32-0.62,8.72-4.58,8.69-14.74l-8.89-4.81l-8.89,4.81c-0.03,10.16,7.37,14.12,8.69,14.74
				C38.39,67.19,38.54,67.19,38.67,67.13z"
          />
          <path
            class="st0"
            d="M38.02,60.28l4.38-4.52c0.32-0.33,0.32-0.86-0.01-1.19v0c-0.33-0.33-0.87-0.33-1.2,0l-3.85,3.85l-1.43-1.43
				c-0.35-0.35-0.91-0.35-1.26,0h0c-0.35,0.35-0.35,0.91,0,1.26l2.05,2.04C37.07,60.66,37.66,60.65,38.02,60.28z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Entrega",
};
</script>
<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: none;
  stroke: #5c48f7;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #5c48f7;
  stroke: #5c48f7;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ff5717;
}

#icon_personalize{
  width: 80px !important;
  height: auto !important;
}
</style>
