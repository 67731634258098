<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    min-width="auto"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        append-icon="fa-regular fa-calendar-days"
        readonly
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" @input="menu = false" locale="es">
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    date: '',
    menu: false,
  }),
  watch:{
    date(newVal){
      this.$emit("update", newVal);
    }
  }
};
</script>
