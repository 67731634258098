import Cookies from "js-cookie";
import * as types from "../mutation-types";

const locale = process.env.VUE_APP_LANG_DEFAULT;
let localesArray = process.env.VUE_APP_LANG.split(","),
  locales = {};

localesArray.forEach((key) => {
  locales[`${key}`] = key;
});

export const state = {
  locale: getLocale(locales, locale),
  locales: locales,
};

export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
};

export const mutations = {
  [types.SET_LOCALE](state, { locale }) {
    state.locale = locale;
  },
};

export const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });
    Cookies.set("locale", locale, { expires: 365 });
    //location.reload();
  },
};

/**
 * @param  {String[]} locales
 * @param  {String} fallback
 * @return {String}
 */
function getLocale(locales, fallback) {
  const locale = Cookies.get("locale");

  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale;
  } else if (locale) {
    Cookies.remove("locale");
  }

  return fallback;
}
