<template>
  <v-container :class="!secondary ? 'px-5 py-8 border_primary' : ''">


    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="tHeader"
          :key="anIncreasingNumberOffers"
          :items="items"
          id="virtual-scroll"
          v-scroll.self="onScroll"
          v-bind="$attrs"
          :loading="loader"
          :loading-text="$t('components.app.datatable.loading_data')"
          :class="loadCursor"
          disable-pagination
          hide-default-footer
          disable-sort
          fixed-header
          v-on="$listeners"
          :style="computedScroll"
        >

          <template
            v-if="filtersOn"
            v-for="header in headers"
            v-slot:[`header.${header.value}`]
            #activator="{ on, attrs }"
          >

            <div class="header-item">
              <div class="header-text">
                <span class="header-title">{{ header.text }}</span>

              </div>
              <div class="header-btn">
                <template>
                  <v-btn
                    icon
                    v-show="header.urlFilter !== ''"
                    text
                    @click="loadFilter(header.name)"
                    :disabled="filterFlag"
                    v-bind:color="
                      selectedFilterOffers[header.name]?.length == 0
                        ? 'primary'
                        : 'tercery'
                    "
                  >
                    <v-icon  size="small"> fa fa-filter </v-icon>
                  </v-btn>
                  <SelectAutocompleteFilter
                    multiple
                    :url="header.urlFilter"
                    v-model="header.vmodel"
                    :label="$t('components.app.datatable.filters')"
                    class="menuFilter"
                    v-show="header.showFilter"
                    @change="selectedFilterOnClick(header.vmodel, header.name)"
                    outlined
                    :clearable="false"
                    :menu-props="{ maxWidth: 180 }"
                    ref="selectFilter"
                    >
                  <template v-slot:selection="{ item }">
                    <v-chip v-if="header.showFilter === 'false'" small>
                      <span ></span>
                    </v-chip>
                  </template>


                  </SelectAutocompleteFilter>
                </template>
              </div>
            </div>
          </template>

          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>

          <template #no-data>
            <p>{{ $t("components.app.datatable.no_results_not_found") }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="informacion-page">
      {{ noPagination ? "" : info }}
    </div>
  </v-container>
</template>

<script>
import validate from "@/plugins/validate";
import moment from 'moment';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DataTableOffers",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    urlCount: {
      type: String,
      default: "",
    },
    searchP: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 300,
    },
    actionsOn: {
      type: Boolean,
      default: false,
    },
    updateOn: {
      type: Boolean,
      default: false,
    },
    noPagine: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    filtersOn: {
      type: Boolean,
      default: false,
    },
    urlFilter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    ...validate,
    localItems: null,
    loader: false,
    newItems: [],
    timeout: null,
    length: 0,
    totalItems: 0,
    perPage: 20,
    rowsConsult: 0,
    totalPages: 0,
    local: 0,
    pageUpdate: 0,
    selectedHeaders: [],
    headersMap: [],
    searchTerm: [],
    valida: false,
    search: "",
    anIncreasingNumberOffers: 1,
    filterActive: false,
    selectedFilterOffers: {
      TypeOperations: [],
      TripNumber: [],
      OriginCities: [],
      NumberOrder: [],
      OriginAddresses: [],
      OriginDates: [],
      DestinationCities: [],
      DestinationAddresses: [],
      DestinationDates: [],
      Amount: [],
      PublicationDates: [],
    },
    filterFlag: false,
  }),
  computed: {
    tHeader() {
      const oHeaders = [...this.showHeaders];
      if (this.actionsOn) {
        oHeaders.push({
          width: 140,
          value: "options",
          text: this.$t("components.app.datatable.options"),
          sortable: false,
          align: "right",
        });
      }
      return oHeaders;
    },
    items() {
      return this.newItems;
    },
    loadCursor() {
      return this.loader
        ? "row-pointer elevation-0 mt-0 parametable"
        : "elevation-0 mt-0 parametable";
    },

    showHeaders() {
      let headersCk = this.headersMap,
        local = this.local;

      if (headersCk.length > 0) {
        if (this.columnsOn) {
          localStorage.setItem(
            `col${this.name}`,
            JSON.stringify(this.selectedHeaders)
          );
        }
      } else {
        local = 0;
      }

      return local > 0
        ? this.selectedHeaders
        : this.headersMap.filter((s) => this.selectedHeaders.includes(s));
    },

    info() {
      let start = this.length === "Healthy" ? 0 : 1;
      let total = this.length === "Healthy" ? 0 : this.length;
      if (!this.noPagine) {
        return this.length
          ? `${this.$t(
              "components.app.datatable.items_displayed"
            )} ${start} - ${this.totalItems} ${this.$t(
              "components.app.datatable.of"
            )} ${total}`
          : "";
      } else {
        return this.length
          ? `${this.$t(
              "components.app.datatable.items_displayed"
            )} ${start} ${this.$t("components.app.datatable.of")} ${total}`
          : "";
      }
    },
    valHeight() {
      return this.height - 230;
    },

    computedScroll() {
        return "height: " + this.valHeight + "px" + ";" + "overflow-x: auto; " + "border: 3px solid #5c48f7;" + "border-radius: 12px;";
    },

    ...mapGetters({
      reloadTrips: "reloadTable/reloadTrips",
    }),

  },
  watch: {
    // Se ejecuta de nuevo cuando cambia el idioma
    headers(value) {
      this.headersMap = Object.values(value);
      this.selectedHeaders = value;
    },

    // reload table desde store
    reloadTrips(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData();
      }
    },

  },
  mounted() {
    this.headersMap = Object.values(this.headers);

    let colsLocalStorage = this.columnsOn
      ? localStorage.getItem(`col${this.name}`)
      : null;

    if (colsLocalStorage !== null) {
      let colsLocalStorageJson = JSON.parse(colsLocalStorage);
      colsLocalStorageJson.splice(
        colsLocalStorageJson.map((e) => e.text).indexOf("Opciones"),
        -1
      );
      this.selectedHeaders = colsLocalStorageJson;
      this.local = 1;
    } else {
      this.selectedHeaders = this.headersMap;
    }
    this.loadData();
    this.getFilterOnClick();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("reloadTable", ["saveReloadTrips"]),

    onScroll(e) {
      if (!this.noPagine) {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.perPage);

          if (rows && this.pageUpdate <= this.totalPages) {
            this.pageUpdate++;
            this.loadData(this.pageUpdate);
            this.rowsConsult = rows;
          }
          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 500);
      }
    },

    async loadLimitCount() {
      let searchProp = this.searchP !== "" ? this.searchP : this.search;

      let search = searchProp == "" ? "" : `?search=${searchProp}`;
      await this.axios.get(`${this.urlCount}${search}`).then((res) => {
        this.length = res.data;
      });
    },

    // Abre filtros
    loadFilter(fieldName) {
      let indexToUpdate = this.headers.findIndex(
        (item) => item.name == fieldName
      );
      if (!this.headers[indexToUpdate].showFilter) {
        this.$refs.selectFilter[indexToUpdate].loadData();
      }
      this.headers[indexToUpdate].showFilter = this.headers[indexToUpdate]
        .showFilter
        ? false
        : true;

      this.headers.forEach((element, index) => {
        if (element.name !== fieldName) {
          this.headers[index].showFilter = false;
        }
      });
    },


    // Guarda filtros seleccionados
    async selectedFilterOnClick(selected, name) {
      this.filterFlag = true;
      this.selectedFilterOffers[name] = selected;

      const publicationDatesSelected = this.selectedFilterOffers.PublicationDates.map((publicationDate) => {
        const date = new Date(publicationDate.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6'));
        const seconds = date.getMilliseconds();
        let ISO = date.toISOString();
        return ISO.replace(/\.\d{3}Z$/, `.${('00' + seconds).slice(-3)}Z`);
      });

      this.selectedFilterOffers.PublicationDates = publicationDatesSelected;

      await this.axios
        .post(`/Filters/saveFiltersOffers`, this.selectedFilterOffers)
        .then(() => {})
        .catch(function (error) {
          const e = error.response
            ? this.$t("components.app.datatable.error_message")
            : error.message.trim();
          this.showSnack({
            text: e,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loadDataNew();
          this.loader = false;
          this.filterFlag = false;
        });
    },

    // Trae filtros guardados
    async getFilterOnClick() {
      await this.axios
        .get(`/Filters/GetFiltersOffers`)
        .then((response) => {
          if (response.data.objectFilter !== "null") {
            this.selectedFilterOffers = JSON.parse(response.data.objectFilter);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loader = false;
        });
    },

    loadDataNew() {
      this.pageUpdate = 0;
      this.loadData();
    },

    async loadData(scrollPage) {
      await this.loadLimitCount();

      let searchProp = this.searchP !== "" ? this.searchP : this.search;

      let search = searchProp == "" ? "" : `?search=${searchProp}`,
        page = scrollPage ? scrollPage : 0;

      this.loader = true;

      try {
        let noPagine = !this.noPagine
          ? `/${this.perPage}/${page}${search}`
          : "";
        const response = await this.axios.get(`${this.url}${noPagine}`);
        if (!this.noPagine) {
          this.localItems = response.data;

          let totalPages = this.length / this.perPage;
          this.totalPages = parseInt(totalPages);

          this.newItems = scrollPage
            ? [...this.newItems, ...this.localItems]
            : [...this.localItems];
          this.totalItems = this.newItems.length;
        } else {
          this.newItems = response.data;
        }
      } catch (error) {
      } finally {
        this.loader = false;
        this.saveReloadTrips({ reloadTrips: false });
      }
    },

    timeElpased(CreatedOn) {
      let now = new Date();
      let countFrom = new Date(CreatedOn).getTime();
      let timeDifference = now - countFrom;
      let secondsInADay = 60 * 60 * 1000 * 24;
      let secondsInAHour = 60 * 60 * 1000;
      let days = Math.floor((timeDifference / secondsInADay) * 1);
      let years = Math.floor(days / 365);

      if (years > 1) {
        days = days - years * 365;
      }

      let hours = Math.floor(
        ((timeDifference % secondsInADay) / secondsInAHour) * 1
      );
      let mins = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
      );

      let hour =
        hours > 0
          ? `${this.$t(
              "components.views.trip.offers.client.card_transport_company.and"
            )} ${hours} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.hours"
            )}`
          : "";

      return `${days} ${this.$t(
        "components.views.trip.offers.client.card_transport_company.days"
      )} ${hour}`;
    },


    formatDate(value) {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },




  },
};
</script>

<style scoped>
@import './DataTableOffers.scss';


#virtual-scroll {
    overflow-y: auto;
    width: 100%;
    display: inline-block;
    border-radius: 0;
  }

  #virtual-scroll >>> thead >>> tr >>> th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  #virtual-scroll >>> thead tr:last-child th {
    background-color: #fff !important;
  /*  border-bottom: 2px solid green !important; */
  /*  border-top: 2px solid green !important; */
  }

  #virtual-scroll >>> thead tr:last-child th span {
    font-size: 13px !important;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: progress;
  }

  #virtual-scroll {
    background: rgba(255, 255, 255, 0.6) !important;
  }

  #virtual-scroll >>> .v-data-table__wrapper {
    overflow-x: unset;
    overflow-y: unset;
  }

  .parametable >>> .actbtn {
    min-width: 35px !important;
    width: 45px !important;
  }

  .parametable tbody  {
    display: table;
    padding: 0em !important;
  }

  /*
  #virtual-scroll >>> tbody tr:nth-child(2n + 1) {
    background-color: #eee;
  } */

  #virtual-scroll >>> tbody tr td:first-child {
  width: 20px;
}

  .parametable th {
    font-size: 0.5em !important;
  }

  .v-list-item {
    min-height: 20px !important;
  }

  .v-application >>> .v-list-item__title {
    font-size: 0.8em;
    background-color: blue;
  }

  .parametable >>> th {
    text-align: center !important;
  }

  .parametable >>> .v-input--selection-controls__input {
    margin: 0 auto !important;
  }

  .v-data-table-header >>> th {
    font-size: 0.8em !important;
    text-align: center !important;
    font-weight: normal;
  }

  #virtual-scroll >>> .v-data-table-header {
    top: 0;
  }

  #virtual-scroll >>> .v-data-table__wrapper {
    height: 40%;
  }

  #virtual-scroll >>> tbody tr .clic,
  #virtual-scroll >>> tbody tr .text-clic {
    cursor: pointer;
  }

  .menuFilter {
  top: 40px;
  left: 140px;
  background-color: #fff;
  width: 180px;
  position: absolute;
  z-index: 1;
}

.menuFilter .v-list-item__title {
  white-space: pre-wrap;
}

.menuFilter >>> .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
.menuFilter >>> .v-text-field__details {
  display: none !important;
}

.item-text-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0;
}

.v-select__selection {
    display: none;
}

.v-select.v-select--hide-details .v-select__selection-text {
  display: none;
}


.header-title {
  font-weight: bolder;
  color: #0B0045;
}

.v-select-list[data-v-ffd93a0c] .v-list-item__title {
  font-size: 0.7em !important;
  color: #5f5f5f;
}

.v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
  font-size: 11px;
}



  /* Pantallas  grandes (1300px o más) */
  @media (min-width: 1300px) {

    td.text-center {
      font-size: 2em !important;
    }
    #virtual-scroll >>> thead tr:last-child th span {
      font-size: 14px !important;
    }
    .v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
        font-size: 12px;
    }


  }

  /* Pantallas extra grandes (1600px o más) */
  @media (min-width: 1600px) {

    .v-data-table__wrapper > table > tbody > tr > td.text-center {
      font-size: 2.2em !important;
    }

    #virtual-scroll >>> thead tr:last-child th span {
      font-size: 14px !important;
    }

    .v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
        font-size: 14px;
    }

  }



</style>

