<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 90 50"
    style="enable-background: new 0 0 90 50"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M22.8,2.05c-3.82,0-6.4,2.29-7.81,4.47s-1.38,2.15-3.75,6.19c0,0-3.37,0-3.8,0c-0.43,0-3.6-0.21-3.9,2.18
		c-0.3,2.34,1.9,2.61,4.26,3.44c-3.08,3.15-3.08,8.59-3.08,8.59v18.48c0,0.91,0.74,1.65,1.65,1.65h5.94c0.91,0,1.65-0.74,1.65-1.65
		v-3.58h17.47h17.47v3.58c0,0.91,0.74,1.65,1.65,1.65h5.94c0.91,0,1.65-0.74,1.65-1.65V26.93c0,0,0-5.44-3.08-8.59
		c2.36-0.82,4.56-1.1,4.26-3.44c-0.31-2.39-3.47-2.18-3.9-2.18c-0.43,0-3.8,0-3.8,0c-2.36-4.05-2.34-4.02-3.75-6.19
		s-3.99-4.47-7.81-4.47H22.8z M40.29,4.83c1.94,0,3.74,0.99,4.79,2.62l5.67,8.84c0.54,0.84-0.06,1.94-1.06,1.94H31.44H13.19
		c-0.99,0-1.59-1.1-1.06-1.94l5.67-8.84c1.05-1.63,2.85-2.62,4.79-2.62H40.29z M16.9,30.73h-5.27c-1.85,0-3.35-1.5-3.35-3.35
		c0-1.85,1.5-3.35,3.35-3.35l5.27,1.78c1.5,0.51,2.46,1.1,2.46,2.46C19.36,29.63,18.26,30.73,16.9,30.73z M37.25,37.46
		c0,0.41-0.33,0.74-0.74,0.74h-5.06h-5.06c-0.41,0-0.74-0.33-0.74-0.74v-2.27c0-0.41,0.33-0.74,0.74-0.74h5.06h5.06
		c0.41,0,0.74,0.33,0.74,0.74V37.46z M51.26,30.73h-5.27c-1.36,0-2.46-1.1-2.46-2.46c0-1.36,0.96-1.95,2.46-2.46l5.27-1.78
		c1.85,0,3.35,1.5,3.35,3.35C54.61,29.23,53.11,30.73,51.26,30.73z"
      />
      <path
        class="st0"
        d="M71.15,33.37l9.52-9.52c1.2-1.2,3.14-1.2,4.34,0v0c1.2,1.2,1.2,3.14,0,4.34L73.27,39.92
		c-1.2,1.2-3.04,1.2-4.24,0l-6.18-6.18c-1.2-1.2-1.2-3.14,0-4.34l0,0c1.2-1.2,3.14-1.2,4.34,0L71.15,33.37L71.15,33.37z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AsignarVehiculo",
};
</script>
<style></style>
