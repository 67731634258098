import Vue from 'vue'
import VueRouter from 'vue-router'
import isAuthenticatedGuard from '@/middleware/auth'

Vue.use(VueRouter);

const route = window.location.host
const mode = route.includes('localhost') ? 'history' : 'hash'

const routes = [
  {
    path: "/",
    beforeEnter: isAuthenticatedGuard,
    name: 'OffersControlT',
    component: () => import(/* webpackChunkName: "Offers" */ "@/layout/Default.vue"),
    children: [
      {
        alias: '/',
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
      },
      {
        path: '/trips/offers',
        name: 'Offers',
        component: () => import(/* webpackChunkName: "TripOffers" */ "@/views/Trips/Offers.vue"),
      },
      {
        path: '/trips/noassign',
        name: 'TripNoAssign',
        component: () => import(/* webpackChunkName: "TripNoAssign" */ "@/views/Trips/NoOfferAssign.vue"),
      },
      {
        path: '/trips/active',
        name: 'TripActive',
        component: () => import(/* webpackChunkName: "TripActive" */ "@/views/Trips/Active.vue"),
      },
      {
        path: '/reports/offers',
        name: 'StatusOffers',
        component: () => import(/* webpackChunkName: "StatusOffers" */ "@/views/Reports/StatusOffers/StatusOffersComponent.vue"),
      },
      {
        path: '/reports/availability',
        name: 'Availability',
        component: () => import(/* webpackChunkName: "Availability" */ "@/views/Reports/Availability/AvailabilityComponent.vue"),
      },
    ]
  },
  {
    path: "/expired",
    name: "Expired",
    component: () => import(/* webpackChunkName: "Expired" */ "@/views/NoPageFound.vue"),
  },
  {
    path: "/*",
    name: "Error",
    component: () => import(/* webpackChunkName: "NoPageNotFound" */ "@/views/NoPageFound.vue"),
  }
];

const router = new VueRouter({
  mode,
  base: process.env.BASE_URL,
  routes,
});

export default router;
